import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Modal, InputNumber, Upload, Select, Switch, Row, Col, message, Radio, Checkbox, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getModels } from '../../store/actions/model';
import { getBrands } from '../../store/actions/brand';
import { getSeries } from '../../store/actions/series';
// import CreatableSelect from 'react-select/creatable';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getCountries } from '../../store/actions/country';


const ProductsUpdateForm = ({ visible, onCreate, onCancel, record, handleDeleteImage }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  
  // Loading states
  const [isBrandLoading, setIsBrandLoading] = useState(true);
  const [isModelLoading, setIsModelLoading] = useState(false);
  const [isSeriesLoading, setIsSeriesLoading] = useState(false);
  const [isModelClick, setModelClick] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);

  // Selection states
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [mrp, setMrp] = useState(null);
  const [price, setPrice] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
      // Control open state of the Select dropdowns
  const [modelOpen, setModelOpen] = useState(false);
  const [seriesOpen, setSeriesOpen] = useState(false);
  const [nameLoaded, setNameLoaded] = useState(false);

  // References for the model and series select components
  const modelSelectRef = useRef(null);
  const seriesSelectRef = useRef(null);

  // Track initial form load to prevent early name reset
  const formInitialized = useRef(false);

    // Focus on the model select input when a brand is selected
    // useEffect(() => {
    //   if (selectedBrand && modelSelectRef.current) {
    //     modelSelectRef.current.focus();
    //     setModelOpen(true);
    //   }
    // }, [selectedBrand]);
  
    // Focus on the series select input when a model is selected
    // useEffect(() => {
    //   if (selectedModel && seriesSelectRef.current) {
    //     seriesSelectRef.current.focus();
    //     setSeriesOpen(true);
    //   }
    // }, [selectedModel]);

  // Redux selectors
  const BrandList = useSelector((state) => state.brand.list);
  const ModelList = useSelector((state) => state.model.list);
  const SeriesList = useSelector((state) => state.series.list);
  const countryList = useSelector((state) => state.country.list);
  
  const [warrantyType, setWarrantyType] = useState(() => {
    if (record?.warrantyDays) return 'warrantyDays';
    if (record?.warrantyMonthly) return 'warrantyMonthly';
    return null;
  });
  
  const formattedDate = record?.warrantyDays
    ? new Date(record.warrantyDays).toISOString().split('T')[0]
    : null;

    // useEffect(() => {
    //   const loadInitialData = async () => {
    //     setIsFormLoading(true);
    //     setIsBrandLoading(true);
    //     try {
    //       // Load brands first
    //       await dispatch(getBrands());
    //       await dispatch(getCountries());
          
    //       // If we have a record, load its related data
    //       if (record) {
    //         if (record.brandId) {
    //           setIsModelLoading(true);
    //           await dispatch(getModels(record.brandId));
    //           setIsModelLoading(false);
    //         }
            
    //         if (record.modelId) {
    //           setIsSeriesLoading(true);
    //           await dispatch(getSeries(record.modelId));
    //           setIsSeriesLoading(false);
    //         }
    //       }
    //     } finally {
    //       setIsBrandLoading(false);
    //       setIsFormLoading(false);
    //       setInitialDataLoaded(true);
    //     }
    //   };
      
    //   loadInitialData();
    // }, [dispatch, record]);

  // Effect to set form values when record changes
  useEffect(() => {
    if (initialDataLoaded && record) {
      setIsFormLoading(true);
      try {
        form.setFieldsValue({
          ...record,
          warrantyDays: formattedDate,
          warrantyType: warrantyType,
          isBestSeller: !!record?.isBestSeller,
          gps: !!record?.gps,
          cellular: !!record?.cellular,
          modelId: record.modelId || undefined,
          seriesId: record.seriesId || undefined,
        });

         // Handle images
      const formattedFileList = record.images?.map((image) => ({
        uid: image._id, // Use a unique identifier for the uid
        name: 'image.jpg', // You can set a default name, or use a dynamic one
        status: 'done', // Status can be 'done', 'uploading', 'error', etc.
        url: image.src,  // URL of the image to be shown
      })) || [];

      // Set the images file list in the form
      form.setFieldsValue({ images: formattedFileList });
        
        setSelectedBrand(record.brandId || null);
        setSelectedModel(record.modelId || null);
        setSelectedSeries(record.seriesId || null);
        setMrp(record.mrp || null);
        setPrice(record.price || null);
      } finally {
        setIsFormLoading(false);
      }
    } else {
      setIsFormLoading(true);
      form.resetFields();
      setSelectedBrand(null);
      setSelectedModel(null);
      setSelectedSeries(null);
      setMrp(null);
      setPrice(null);
      setDiscountPercentage(null);
      setIsFormLoading(false);
    }
  }, [initialDataLoaded, record, form, formattedDate, warrantyType]);

  // Load models when brand changes
  // useEffect(() => {
  //   const loadModels = async () => {
  //     if (selectedBrand) {
  //       setIsModelLoading(true);
  //       try {
  //         await dispatch(getModels(selectedBrand));
  //         // Clear selected model and series when brand changes
  //         setSelectedModel(null);
  //         setSelectedSeries(null);
  //         form.setFieldsValue({ modelId: undefined, seriesId: undefined });
  //       } finally {
  //         setIsModelLoading(false);
  //       }
  //     }
  //   };
  //   loadModels();
  // }, [selectedBrand, dispatch, form]);

  useEffect(() => {
    const loadInitialData = async () => {
      setIsFormLoading(true);
      setIsBrandLoading(true);
      try {
        // Load brands first
        await dispatch(getBrands());
        await dispatch(getCountries());
        
        // If we have a record, load its related data
        if (record) {
          if (record.brandId) {
            setIsModelLoading(true);
            await dispatch(getModels(record.brandId));
            setIsModelLoading(false);
          }
          
          if (record.modelId) {
            setIsSeriesLoading(true);
            // setIsFormLoading(true);
            setNameLoaded(false);
            console.log("isformloading before: ", isFormLoading);
            console.log("nameloaded before: ", nameLoaded);
            await dispatch(getSeries(record.modelId));
            setNameLoaded(true);
            console.log("nameloaded after: ", nameLoaded);
            setIsSeriesLoading(false);
          }
        }
      } finally {
        setIsBrandLoading(false);
        setInitialDataLoaded(true);
        setIsFormLoading(true);
      }
    };
    
    loadInitialData();
  }, [dispatch, record]);
  
    // // Update name when brand, model, or series changes
    useEffect(() => {
      // const brand = BrandList.find(b => b._id === selectedBrand);
      const model = ModelList.find((m) => m._id === selectedModel);
      const series = SeriesList.find((s) => s._id === selectedSeries);
  
      // const name = `${brand?.name || ''} ${model?.name || ''} ${series?.name || ''}`.trim();
      const computedName = `${model?.name || ""} ${series?.name || ""} | ${
        series?.color || ""
      } ${series?.ram || ""}GB + ${series?.storage || ""}GB`.trim();

      
      // if (isFormLoading && record !== null) {
      //   // If the form is loaded and the name hasn't been set yet, set the original name from record
      //   form.setFieldsValue({ name: record.name });
      //   setNameLoaded(true); // Mark name as set from the record
      // } else {
      //   // If it's already set or the form has loaded, update with computed name
      //   form.setFieldsValue({ name: computedName });
      // }

      if (!isModelClick) {
        if (record !== null && record.name !== computedName) {
          console.log("original value is here: ", record.name);
          form.setFieldsValue({ name:record.name });
          
            // setIsFormLoading(false);
          }
          else {
            form.setFieldsValue({ name: computedName });
          }
      }
      else {
        console.log("computed value is here: ", computedName);
        form.setFieldsValue({ name: computedName });
      }

    }, [
      selectedBrand,
      selectedModel,
      selectedSeries,
      BrandList,
      ModelList,
      SeriesList,
      form,
      record,
      isModelClick
    ]);

  // Handlers
  const handleBrandChange = (value) => {
    setSelectedBrand(value);
  };

  const handleModelChange = (value) => {
    const model = ModelList.find((s) => s._id === value);
    if (model) {
      form.setFieldsValue({
        category: model.category,
      });
    }
    setSelectedModel(value);
    setSelectedSeries(null);
    form.setFieldsValue({ seriesId: undefined })
  };

  // const handleModelChange = (value) => {
  //   setSelectedModel(value);
  // };

  const handleSeriesChange = (value) => {
    const series2 = SeriesList.find((s) => s._id === value); 
    if (series2) {
      form.setFieldsValue({
        ram: series2.ram,
        storage: series2.storage,
        color: series2.color,
        // images: series.images && series.images.length > 0 ? [{
        //   uid: '-1', // Unique identifier for the uploaded image
        //   name: series.images[0].fileName, // The image filename
        //   status: 'done', // Status of the upload
        //   url: series.images[0].imageUrl // Image URL to display
        // }] : []
      });
    }
    setSelectedSeries(value);
  };

    // Filter models based on selected brand
    const filteredModels = ModelList.filter(
      (model) => model.brandId === selectedBrand
    );
  
    const filteredSeries = SeriesList.filter(
      (series) => series.modelId === selectedModel
    );

  // Calculate discount percentage
  useEffect(() => {
    if (mrp && price && mrp > 0) {
      const discount = ((mrp - price) / mrp) * 100;
      setDiscountPercentage(discount.toFixed());
      form.setFieldsValue({ disPer: discount.toFixed() });
    } else {
      setDiscountPercentage(0);
      form.setFieldsValue({ disPer: 0 });
    }
  }, [mrp, price, form]);

  // Render select components with loading states
  const renderBrandSelect = () => (
    <Form.Item
      name="brandId"
      label="Brand"
      rules={[{ required: true, message: 'Please select a brand!' }]}
    >
      <Select 
        placeholder={isBrandLoading ? "Loading brands..." : "Select a brand"}
        onChange={handleBrandChange}
        loading={isBrandLoading}
        disabled={isBrandLoading}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {BrandList.map((brand) => (
          <Select.Option key={brand._id} value={brand._id}>
            {brand.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const renderModelSelect = () => (
    <Form.Item
      name="modelId"
      label="Series"
      rules={[{ required: true, message: 'Please select a series!' }]}
    >
      <Select 
        ref={modelSelectRef}
        placeholder={isModelLoading ? "Loading series..." : "Select a series"}
        onChange={handleModelChange}
        onClick={() => setModelClick(true)}
        loading={isModelLoading}
        disabled={isModelLoading || !selectedBrand}
        open={modelOpen} // Control if the dropdown should be open
                onDropdownVisibleChange={(open) => setModelOpen(open)}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {filteredModels.map((model) => (
          <Select.Option key={model._id} value={model._id}>
            {model.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const renderSeriesSelect = () => (
    <Form.Item
      name="seriesId"
      label="Model"
      rules={[{ required: true, message: 'Please select a model!' }]}
    >
      <Select 
        ref={seriesSelectRef} 
        placeholder={isSeriesLoading ? "Loading model..." : "Select a model"}
        onChange={handleSeriesChange}
        loading={isSeriesLoading}
        onClick={() => setModelClick(true)}
        disabled={isSeriesLoading || !selectedModel}
        open={seriesOpen} // Control if the dropdown should be open
        onDropdownVisibleChange={(open) => setSeriesOpen(open)}
        showSearch
        filterOption={(input, option) => {
          // Ensure option.children is treated as a string before calling toLowerCase
          const children = typeof option.children === 'string' ? option.children : option.children.toString();
          return children.toLowerCase().includes(input.toLowerCase());
        }}
      >
        {filteredSeries.map((series) => (
          <Select.Option key={series._id} value={series._id}>
            {series.name} | {series.color} | {series.ram}GB + {series.storage}GB
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onWarrantyTypeChange = (e) => {
    const newType = e.target.value;
    setWarrantyType(newType);
    
    // Clear the fields based on selection
    if (newType === 'warrantyDays') {
      form.setFieldsValue({
        warrantyMonthly: null,
        warrantyDays: null,  // Reset date when switching
      });
    } else if (newType === 'warrantyMonthly') {
      form.setFieldsValue({
        warrantyDays: null,
        warrantyMonthly: null,  // Reset months when switching
      });
    }
  };

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      if (!isPNG && !isJPG) {
        return false; // Reject the upload if not PNG/JPEG
      }
      return true; // Allow the upload if it is PNG/JPEG
    },
  
    onChange: (info) => {
      // Filter out any invalid files
      let fileList = info.fileList.filter((file) => {
        const isPNG = file.type === "image/png";
        const isJPG = file.type === "image/jpeg";
        if (!isPNG && !isJPG) {
          message.error(`${file.name} is not a valid image (PNG/JPEG)`);
        }
        return isPNG || isJPG;
      });
      form.setFieldsValue({ images: fileList });
    },

    onRemove: async (file) => {
      // You can access the file id here and call handleDelete
      const imageId = file.uid; // Assuming the UID of the file is the image ID
      await handleDeleteImage(imageId);
      return true; // Proceed with the removal from the list
    },
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Product" : "Add Product"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="product_form"
        // initialValues={record}
        initialValues={{
          ...record,
          isBestSeller: !!record?.isBestSeller,      // Convert to boolean explicitly
          gps: !!record?.gps,      // Convert to boolean explicitly
          cellular: !!record?.cellular,
          modelId: record?.modelId,
          seriesId: record?.seriesId
        }}
      >
        <Row gutter={16}>
          <Col span={8}>
          {renderBrandSelect()}
            {/* <Form.Item
              name="brandId"
              label="Brand"
              rules={[{ required: true, message: 'Please select a brand!' }]}
            >
              <Select placeholder="Select a brand" onChange={handleBrandChange} showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }>
                {BrandList.map((brand) => (
                  <Select.Option key={brand._id} value={brand._id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
          </Col>
          
          <Col span={8}>
          {renderModelSelect()}
            {/* <Form.Item
              name="modelId"
              label="Model"
              rules={[{ required: true, message: 'Please select a model!' }]}
            >
              <Select 
              placeholder="Select a model" 
              onChange={handleModelChange} showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }>
                {ModelList.map((model) => (
                  <Select.Option key={model._id} value={model._id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
          </Col>
          <Col span={8}><Form.Item
                name="category"
                label="Category"
              >
              <Select placeholder="Select a Category type" disabled>
                <Select.Option value="Mobile">Mobile</Select.Option>
                <Select.Option value="Tablet">Tablet</Select.Option>
                <Select.Option value="Watch">Watch</Select.Option>
                <Select.Option value="Airpod">Airpod</Select.Option>
                <Select.Option value="Accessories">Accessories</Select.Option>
              </Select>
            </Form.Item></Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
          {renderSeriesSelect()}
            {/* <Form.Item
              name="seriesId"
              label="Series"
              rules={[{ required: true, message: 'Please select a series!' }]}
            >
              <Select placeholder="Select a series" onChange={handleSeriesChange} showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }>
                {SeriesList.map((series) => (
                  <Select.Option key={series._id} value={series._id}>
                    {series.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter the product name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>      
          <Col span={8}>
            <Form.Item
              name="mrp"
              label="MRP"
              // rules={[
              //   { required: true, message: "Please enter the MRP!" },
              //   () => ({
              //     validator(_, value) {
              //       if (value && price && value < price) {
              //         return Promise.reject(new Error("MRP should not be less than Price"));
              //       }
              //       return Promise.resolve();
              //     },
              //   }),
              // ]}
            >
              <InputNumber
                type="number"
                min={0}
                style={{ width: "100%" }}
                value={mrp}
                onChange={setMrp}
                disabled
              /> 
            </Form.Item>
          </Col>
          <Col span={8}>
          <Form.Item
              name="price"
              label="Price"
              rules={[
                { required: true, message: "Please enter the price!" },
                () => ({
                  validator(_, value) {
                    if (value && mrp && value > mrp) {
                      return Promise.reject(new Error("Price should not be greater than MRP"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                style={{ width: "100%" }}
                value={price}
                onChange={setPrice}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="disPer"
              label="Discount (%)"
              // rules={[{ type: 'number', min: 0, max: 100, message: "Discount must be between 0 and 100!" }]}
            >
              <InputNumber disabled
                type="number"
                min={0}
                max={100}
                style={{ width: "100%" }}
                value={discountPercentage} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="imei"
              label="IMEI"
              rules={[
                { required: true, message: 'Please enter the IMEI!' },
                { max: 15, message: 'IMEI must be at most 15 characters long!' }
              ]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="imei2"
              label="IMEI2"
              rules={[
                // { required: true, message: 'Please enter the IMEI2!' },
                { max: 15, message: 'IMEI2 must be at most 15 characters long!' }
              ]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="serialNumber"
              label="serialNumber"
              // rules={[
              //   { max: 15, message: 'Serial Number must be at most 15 characters long!' }
              // ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
              name="color"
              label="Color"
              // rules={[{ required: true, message: 'Please enter the color!' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="storage"
              label="Storage (GB)"
              // rules={[{ required: true, message: 'Please enter the storage!' }]}
            >
              <Input type='number' disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="ram"
              label="RAM (GB)"
            >
              <Input type='number' disabled />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="about" label="Description (About Product Details)">
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
                  ],
                }}
                style={{ height: '150px' }}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col span={4}>
          <Form.Item 
            name="batteryHealth" 
            label="Battery(%)"
            validateFirst={true}
            // validateFirst={false}
            // rules={[
            //   { 
            //     type: "number",
            //     min: 0,
            //     max: 100,
            //     transform: (value) => {
            //       if (value) {
            //         return Number(value);
            //       }
            //       // return null;
            //       return undefined
            //     },
            //     message: "Please enter the Valid Battery (%)"
            //   }
            // ]}
          >
            <InputNumber 
              type="number" 
              min={0}
              max={100}
              style={{ width: '100%' }}
              parser={value => value.replace(/[^0-9]/g, '')}
            />
          </Form.Item>
            {/* <Form.Item
              name="batteryHealth"
              label="Battery(%)"
              rules={[
                {
                  // required: false,
                  // type: "number",
                  min: 0,
                  max: 100,
                  message: "Please enter the Valid Battery (%)",
                },
              ]}
            >
              <InputNumber  
              min={0} 
              max={100} 
              // type="number"
               />
            </Form.Item> */}
          </Col>

          <Col span={4}>
          <Form.Item 
            name="cond" 
            label="Condition(%)"
            validateFirst={true}
            rules={[
              { 
                type: "number",
                min: 0,
                max: 100,
                transform: (value) => {
                  if (value) {
                    return Number(value);
                  }
                  return null;
                },
                message: "Please enter valid Condition!"
              }
            ]}
          >
            <InputNumber 
              type="number" 
              min={0}
              max={100}
              style={{ width: '100%' }}
              parser={value => value.replace(/[^0-9]/g, '')}
            />
          </Form.Item>
            {/* <Form.Item
              name="cond"
              label="Condition(%)"
              rules={[
                { 
                  // required: false,
                  // type: "number",
                  min: 0,
                  max: 100, 
                  message: "Please enter valid Condition!" },
              ]}
            >
              <InputNumber 
              // type="number"  
              min={0}
                max={100} />
            </Form.Item> */}
          </Col>
          <Col span={8}>
            <Form.Item name="processor" label="Processor">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="size" label="Size">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
           <Form.Item name="network" label="Network">
              <Select showSearch placeholder="Select a network type" disabled>
                <Select.Option value="4g">4G</Select.Option>
                <Select.Option value="5g">5G</Select.Option>
                <Select.Option value="3g">3G</Select.Option>
                <Select.Option value="lte">LTE</Select.Option>
                {/* Add other network types if needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
          <Form.Item
              name="countryId"
              label="Country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Select placeholder="Select a country" showSearch
                // disabled
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }>
                {countryList.map((country) => (
                  <Select.Option key={country._id} value={country._id}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
          <Form.Item name="simVariant" label="SIM">
              <Select showSearch placeholder="Select a SIM Variant">
                <Select.Option value="Duel">Duel</Select.Option>
                <Select.Option value="Single">Single</Select.Option>
                <Select.Option value="Duel eSIM">Duel eSIM</Select.Option>
                <Select.Option value="Duel (1 eSIM + 1 Physical)">
                  Duel (1 eSIM + 1 Physical)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{marginTop:30}}>
          <Col span={24}>
            <Form.Item name="goodnote" label="Good Note">
              <Input.TextArea style={{background:"#caffbf"}}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="rednote" label="Red Note">
              <Input.TextArea style={{background:"#ffc4bf"}} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
          <Form.Item 
            name="battery" 
            label="Battery in mAh"
            validateFirst={true}
            // rules={[
            //   { 
            //     type: "number",
            //     min: 0,
            //     transform: (value) => {
            //       if (value) {
            //         return Number(value);
            //       }
            //       return null;
            //     },
            //     message: "Battery capacity must be greater than or equal to 0!"
            //   }
            // ]}
          >
            <InputNumber 
              type="number" 
              disabled
              // min={0}
              // style={{ width: '100%' }}
              // parser={value => value.replace(/[^0-9]/g, '')}
            />
          </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="guarantee" label="Garrenty">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="warranty" label="Warranty">
              <Select showSearch placeholder="Select product Warrenty">
                <Select.Option value="Brand">Brand</Select.Option>
                <Select.Option value="Store">Store</Select.Option>
                <Select.Option value="None">None</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="warrantyType" label="Warranty Type">
              <Radio.Group onChange={onWarrantyTypeChange}>
                <Radio value="warrantyDays">Date</Radio>
                <Radio value="warrantyMonthly">Monthly</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {warrantyType === "warrantyDays" && (
            <Col span={8}>
              <Form.Item
                name="warrantyDays"
                label="Warranty Date"
              >
                <Input type="date" placeholder="Enter warranty Date" />
              </Form.Item>
            </Col>
          )}
          {warrantyType === "warrantyMonthly" && (
            <Col span={8}>
              <Form.Item
                name="warrantyMonthly"
                label="Monthly Warrenty"
              >
                <Input type="number" placeholder="Enter warranty Months" />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
                name="mmSize"
                label="mmSize"
              >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="usedType" label="Used Type">
              <Select showSearch placeholder="Select this product Used Type">
                <Select.Option value="USED">USED</Select.Option>
                <Select.Option value="REFURBISHED">REFURBISHED</Select.Option>
                <Select.Option value="NEW">NEW</Select.Option>
                <Select.Option value="REPLACED">REPLACED</Select.Option>
              </Select>
            </Form.Item>
          </Col>   
          <Col span={4}>
            <Form.Item
              name="gps"
              label=" "
              // initialValue={record.gps}
              valuePropName="checked" // Maps checkbox value to true/false
              // initialValue={false}
            >
              <Checkbox disabled>GPS</Checkbox>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="cellular"
              label=" "
              valuePropName="checked" // Maps checkbox value to true/false
              // initialValue={false}
            >
              <Checkbox disabled>Cellular</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="batteryCycle" label="Battery Cycle">
                <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
                name="box"
                label=" "
                valuePropName="checked" // Maps checkbox value to true/false
                getValueFromEvent={(e) => Boolean(e.target.checked)}
                initialValue={false}
              >
                <Checkbox>Box</Checkbox>
              </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
                name="loose"
                label=" "
                valuePropName="checked" // Maps checkbox value to true/false
                getValueFromEvent={(e) => Boolean(e.target.checked)}
                initialValue={false}
              >
                <Checkbox>Loose</Checkbox>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
                name="damage"
                label=" "
                valuePropName="checked" // Maps checkbox value to true/false
                getValueFromEvent={(e) => Boolean(e.target.checked)}
                initialValue={false}
              >
                <Checkbox>Damage</Checkbox>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="isBestSeller"
              label=" "
              valuePropName="checked" // Maps checkbox value to true/false
              getValueFromEvent={(e) => Boolean(e.target.checked)}
              initialValue={false}
            >
              <Checkbox>Best Seller</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="bill" label="Bill">
              <Select showSearch placeholder="Select this product Bill">
                <Select.Option value="ORIGINAL">ORIGINAL</Select.Option>
                <Select.Option value="STORE">STORE</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="kit" label="Kit">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="youtubeUrl"
              label="YouTube URL"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          
          <Col span={24}>
            <Form.Item
              name="images"
              label="Images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload {...props} listType="picture-card" accept="image/png, image/jpeg" multiple>
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductsUpdateForm;






// import React, { useEffect, useState } from 'react';
// import { Form, Input, Modal, InputNumber, Upload, Select, Switch, Row, Col, message, Radio, Checkbox } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
// import { useDispatch, useSelector } from 'react-redux';
// import { getModels } from '../../store/actions/model';
// import { getBrands } from '../../store/actions/brand';
// import { getSeries } from '../../store/actions/series';
// // import CreatableSelect from 'react-select/creatable';
// // import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { getCountries } from '../../store/actions/country';

// const ProductsUpdateForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   // const [warrantyType, setWarrantyType] = useState(null);
//   const [selectedBrand, setSelectedBrand] = useState(null);
//   const [selectedModel, setSelectedModel] = useState(null);
//   const [selectedSeries, setSelectedSeries] = useState(null);
//   const [mrp, setMrp] = useState(null);
//   const [price, setPrice] = useState(null);
//   const [discountPercentage, setDiscountPercentage] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);

//   const BrandList = useSelector((state) => state.brand.list);
//   const ModelList = useSelector((state) => state.model.list);
//   const SeriesList = useSelector((state) => state.series.list);
//   const countryList = useSelector((state) => state.country.list);
//   const [warrantyType, setWarrantyType] = useState(() => {
//     if (record?.warrantyDays) return 'warrantyDays';
//     if (record?.warrantyMonthly) return 'warrantyMonthly';
//     return null;
//   });
//   const formattedDate = record?.warrantyDays
//     ? new Date(record.warrantyDays).toISOString().split('T')[0]
//     : null;

//   useEffect(() => {
//     // Check if 'record' is being fetched or if it's available
//     if (record) {
//       setIsLoading(false);
//     } else {
//       setIsLoading(true);
//     }
//   }, [record]);
  

//   useEffect(() => {
//     // Fetch brands on component mount
//     dispatch(getModels());
//     dispatch(getBrands());
//     dispatch(getSeries());
//     dispatch(getCountries())
//   }, [dispatch]);

//   useEffect(() => {
//     // form.setFieldsValue(record);
//     form.setFieldsValue({
//       ...record,
//       warrantyDays: formattedDate,
//       warrantyType: warrantyType,
//       gps: !!record?.gps,         // Convert to boolean explicitly
//       cellular: !!record?.cellular
//     });
//   }, [record, form, formattedDate, warrantyType]);

//     // // Update name when brand, model, or series changes


//       // Function to calculate discount percentage
//   const calculateDiscount = () => {
//     if (mrp && price && mrp > 0) {
//       const discount = ((mrp - price) / mrp) * 100;
//       setDiscountPercentage(discount.toFixed());
//       form.setFieldsValue({ disPer: discount.toFixed() });
//     } else {
//       setDiscountPercentage(0); // Reset if any value is invalid
//       form.setFieldsValue({ disPer: 0 });
//     }
//   };
//   useEffect(() => {
//     calculateDiscount();
//   }, [mrp, price]);

//   useEffect(() => {
//     if (selectedBrand) {
//       dispatch(getModels(selectedBrand));
//       // Clear selected model and series when brand changes 
//       setSelectedModel(null);
//       setSelectedSeries(null);
//       form.setFieldsValue({ modelId: undefined, seriesId: undefined });
//     }
//   }, [selectedBrand, dispatch, form]);

//   useEffect(() => {
//     if (selectedModel) {
//       dispatch(getSeries(selectedModel));
//       setSelectedSeries(null);
//       // Clear selected series when model changes
//       form.setFieldsValue({ seriesId: undefined });
//     }
//   }, [selectedModel, dispatch, form]);

//   useEffect(() => {
//     if (record) {
//       form.setFieldsValue({
//         // ...record,
//         // qty: record.qty || 0,
//         qty: 0,
//       });
//       setSelectedBrand(record.brandId);
//       setSelectedModel(record.modelId);
//       setSelectedSeries(record.seriesId);
//       setMrp(record.mrp);
//       setPrice(record.price);
//     } else {
//       form.resetFields();
//       setSelectedBrand(null);
//       setSelectedModel(null);
//       setSelectedSeries(null);
//       setMrp(null);
//       setPrice(null);
//       setDiscountPercentage(null);
//     }
//   }, [record, form]);

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     return e?.fileList;
//   };

//   const handleBrandChange = (value) => {
//     setSelectedBrand(value);
//   };
//   const handleModelChange = (value) => {
//     setSelectedModel(value);
//   };
//   const handleSeriesChange = (value) => {
//     setSelectedSeries(value);
//   };

//   const handleChange = (value) => {
//     console.log(`selected ${value}`);
//   };
//   // // Filter models based on selected brand
//   // const filteredModels = ModelList.filter(
//   //   (model) => model.brandId === selectedBrand
//   // );

//   // const filteredSeries = SeriesList.filter(
//   //   (series) => series.modelId === selectedModel
//   // );

//   const onWarrantyTypeChange = (e) => {
//     const newType = e.target.value;
//     setWarrantyType(newType);
    
//     // Clear the fields based on selection
//     if (newType === 'warrantyDays') {
//       form.setFieldsValue({
//         warrantyMonthly: null,
//         warrantyDays: null  // Reset date when switching
//       });
//     } else if (newType === 'warrantyMonthly') {
//       form.setFieldsValue({
//         warrantyDays: null,
//         warrantyMonthly: null  // Reset months when switching
//       });
//     }
//   };

//   const props = {
//     beforeUpload: (file) => {
//       const isPNG = file.type === "image/png";
//       const isJPG = file.type === "image/jpeg";
//       if (!isPNG && !isJPG) {
//         // message.error(`${file.name} is not a png/jpeg file`);
//         return false; // Reject the upload if not PNG/JPEG
//       }
//       return true; // Allow the upload if it is PNG/JPEG
//     },
  
//     onChange: (info) => {
//       // Filter out any invalid files
//       let fileList = info.fileList.filter((file) => {
//         const isPNG = file.type === "image/png";
//         const isJPG = file.type === "image/jpeg";
//         if (!isPNG && !isJPG) {
//           // Show error message for invalid file type
//           message.error(`${file.name} is not a valid image (PNG/JPEG)`);
//         }
//         return isPNG || isJPG;
//       });
//       // Update the file list to only include valid images
//       form.setFieldsValue({ images: fileList });
//     },
//   };

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Product" : "Add Product"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="product_form"
//         // initialValues={record}
//         initialValues={{
//           ...record,
//           gps: !!record?.gps,      // Convert to boolean explicitly
//           cellular: !!record?.cellular
//         }}
//       >
//         <Row gutter={16}>
//           <Col span={8}>
//             <Form.Item
//               name="brandId"
//               label="Brand"
//               rules={[{ required: true, message: 'Please select a brand!' }]}
//             >
//               <Select placeholder="Select a brand" onChange={handleBrandChange} showSearch
//                 filterOption={(input, option) =>
//                   option.children.toLowerCase().includes(input.toLowerCase())
//                 }>
//                 {BrandList.map((brand) => (
//                   <Select.Option key={brand._id} value={brand._id}>
//                     {brand.name}
//                   </Select.Option>
//                 ))}
//               </Select>
//             </Form.Item>
//           </Col>
//           <Col span={8}><Form.Item
//                 name="category"
//                 label="Category"
//               >
//               <Select placeholder="Select a Category type">
//                 <Select.Option value="Mobile">Mobile</Select.Option>
//                 <Select.Option value="Tablet">Tablet</Select.Option>
//                 <Select.Option value="Watch">Watch</Select.Option>
//                 <Select.Option value="Airpod">Airpod</Select.Option>
//                 <Select.Option value="Accessories">Accessories</Select.Option>
//               </Select>
//             </Form.Item></Col>
//           <Col span={8}>
//             <Form.Item
//               name="modelId"
//               label="Model"
//               rules={[{ required: true, message: 'Please select a model!' }]}
//             >
//               <Select 
//               placeholder="Select a model" 
//               onChange={handleModelChange} showSearch
//                 filterOption={(input, option) =>
//                   option.children.toLowerCase().includes(input.toLowerCase())
//                 }>
//                 {ModelList.map((model) => (
//                   <Select.Option key={model._id} value={model._id}>
//                     {model.name}
//                   </Select.Option>
//                 ))}
//               </Select>
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
//           <Col span={12}>
//             <Form.Item
//               name="seriesId"
//               label="Series"
//               rules={[{ required: true, message: 'Please select a series!' }]}
//             >
//               <Select placeholder="Select a series" onChange={handleSeriesChange} showSearch
//                 filterOption={(input, option) =>
//                   option.children.toLowerCase().includes(input.toLowerCase())
//                 }>
//                 {SeriesList.map((series) => (
//                   <Select.Option key={series._id} value={series._id}>
//                     {series.name}
//                   </Select.Option>
//                 ))}
//               </Select>
//             </Form.Item>
//           </Col>
//           <Col span={12}>
//             <Form.Item
//               name="name"
//               label="Name"
//               rules={[{ required: true, message: 'Please enter the product name!' }]}
//             >
//               <Input />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>      
//           <Col span={8}>
//             <Form.Item
//               name="mrp"
//               label="MRP"
//               rules={[
//                 { required: true, message: "Please enter the MRP!" },
//                 () => ({
//                   validator(_, value) {
//                     if (value && price && value < price) {
//                       return Promise.reject(new Error("MRP should not be less than Price"));
//                     }
//                     return Promise.resolve();
//                   },
//                 }),
//               ]}
//             >
//               <InputNumber
//                 type="number"
//                 min={0}
//                 style={{ width: "100%" }}
//                 value={mrp}
//                 onChange={setMrp}
//               /> 
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//           <Form.Item
//               name="price"
//               label="Price"
//               rules={[
//                 { required: true, message: "Please enter the price!" },
//                 () => ({
//                   validator(_, value) {
//                     if (value && mrp && value > mrp) {
//                       return Promise.reject(new Error("Price should not be greater than MRP"));
//                     }
//                     return Promise.resolve();
//                   },
//                 }),
//               ]}
//             >
//               <InputNumber
//                 type="number"
//                 min={0}
//                 style={{ width: "100%" }}
//                 value={price}
//                 onChange={setPrice}
//               />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item
//               name="disPer"
//               label="Discount (%)"
//               // rules={[{ type: 'number', min: 0, max: 100, message: "Discount must be between 0 and 100!" }]}
//             >
//               <InputNumber disabled
//                 type="number"
//                 min={0}
//                 max={100}
//                 style={{ width: "100%" }}
//                 value={discountPercentage} />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
//           <Col span={8}>
//             <Form.Item
//               name="imei"
//               label="IMEI"
//               rules={[
//                 { required: true, message: 'Please enter the IMEI!' },
//                 { max: 15, message: 'IMEI must be at most 15 characters long!' }
//               ]}
//             >
//               <Input type='number' />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item
//               name="imei2"
//               label="IMEI2"
//               rules={[
//                 // { required: true, message: 'Please enter the IMEI2!' },
//                 { max: 15, message: 'IMEI2 must be at most 15 characters long!' }
//               ]}
//             >
//               <Input type='number' />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item
//               name="serialNumber"
//               label="serialNumber"
//               rules={[
//                 { max: 15, message: 'Serial Number must be at most 15 characters long!' }
//               ]}
//             >
//               <Input />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
//         <Col span={8}>
//             <Form.Item
//               name="color"
//               label="Color"
//               // rules={[{ required: true, message: 'Please enter the color!' }]}
//             >
//               <Input disabled />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item
//               name="storage"
//               label="Storage (GB)"
//               // rules={[{ required: true, message: 'Please enter the storage!' }]}
//             >
//               <Input type='number' disabled />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item
//               name="ram"
//               label="RAM (GB)"
//             >
//               <Input type='number' disabled />
//             </Form.Item>
//           </Col>
//         </Row>
//         {/* <Row gutter={16}>
//           <Col span={24}>
//             <Form.Item name="about" label="Description (About Product Details)">
//               <ReactQuill
//                 theme="snow"
//                 modules={{
//                   toolbar: [
//                     [{ 'header': [1, 2, false] }],
//                     ['bold', 'italic', 'underline'],
//                     [{ 'list': 'ordered' }, { 'list': 'bullet' }]
//                   ],
//                 }}
//                 style={{ height: '150px' }}
//               />
//             </Form.Item>
//           </Col>
//         </Row> */}
//         <Row gutter={16}>
//           <Col span={4}>
//             <Form.Item
//               name="batteryHealth"
//               label="Battery(%)"
//               rules={[
//                 {
//                   // required: false,
//                   // type: "number",
//                   min: 0,
//                   max: 100,
//                   message: "Please enter the Valid Battery (%)",
//                 },
//               ]}
//             >
//               <InputNumber  
//               min={0} 
//               max={100} 
//               // type="number"
//                />
//             </Form.Item>
//           </Col>

//           <Col span={4}>
//             <Form.Item
//               name="cond"
//               label="Condition(%)"
//               rules={[
//                 { 
//                   // required: false,
//                   // type: "number",
//                   min: 0,
//                   max: 100, 
//                   message: "Please enter valid Condition!" },
//               ]}
//             >
//               <InputNumber 
//               // type="number"  
//               min={0}
//                 max={100} />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item name="processor" label="Processor">
//               <Input />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item name="size" label="Size">
//               <Input />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
//           <Col span={8}>
//            <Form.Item name="network" label="Network">
//               <Select showSearch placeholder="Select a network type">
//                 <Select.Option value="4g">4G</Select.Option>
//                 <Select.Option value="5g">5G</Select.Option>
//                 <Select.Option value="3g">3G</Select.Option>
//                 <Select.Option value="lte">LTE</Select.Option>
//                 {/* Add other network types if needed */}
//               </Select>
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//           <Form.Item
//               name="countryId"
//               label="Country"
//               rules={[{ required: true, message: "Please select a country!" }]}
//             >
//               <Select placeholder="Select a country" showSearch
//                 filterOption={(input, option) =>
//                   option.children.toLowerCase().includes(input.toLowerCase())
//                 }>
//                 {countryList.map((country) => (
//                   <Select.Option key={country._id} value={country._id}>
//                     {country.name}
//                   </Select.Option>
//                 ))}
//               </Select>
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//           <Form.Item name="simVariant" label="SIM">
//               <Select showSearch placeholder="Select a SIM Variant">
//                 <Select.Option value="Duel">Duel</Select.Option>
//                 <Select.Option value="Single">Single</Select.Option>
//                 <Select.Option value="Duel eSIM">Duel eSIM</Select.Option>
//                 <Select.Option value="Duel (1 eSIM + 1 Physical)">
//                   Duel (1 eSIM + 1 Physical)
//                 </Select.Option>
//               </Select>
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16} style={{marginTop:30}}>
//           <Col span={24}>
//             <Form.Item name="goodnote" label="Good Note">
//               <Input.TextArea style={{background:"#caffbf"}}/>
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
//           <Col span={24}>
//             <Form.Item name="rednote" label="Red Note">
//               <Input.TextArea style={{background:"#ffc4bf"}} />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
//           <Col span={4}>
//           <Form.Item name="battery" label="Battery in mAh" rules={[
//                 { 
//                   // required: true,
//                   type: "number",
//                   min: 0,           
//                   message: "Please enter valid Battery mAh!" },
//               ]}>
//               <InputNumber type="number" min={0} />
//             </Form.Item>
//           </Col>
//           <Col span={4}>
//             <Form.Item name="guarantee" label="Guarantee">
//               <Input />
//             </Form.Item>
//           </Col>
//           <Col span={4}>
//             <Form.Item name="warranty" label="Warranty">
//               <Select showSearch placeholder="Select product Warrenty">
//                 <Select.Option value="Brand">Brand</Select.Option>
//                 <Select.Option value="Store">Store</Select.Option>
//                 <Select.Option value="None">None</Select.Option>
//               </Select>
//             </Form.Item>
//           </Col>
//           <Col span={4}>
//             <Form.Item name="warrantyType" label="Warranty Type">
//               <Radio.Group onChange={onWarrantyTypeChange}>
//                 <Radio value="warrantyDays">Date</Radio>
//                 <Radio value="warrantyMonthly">Monthly</Radio>
//               </Radio.Group>
//             </Form.Item>
//           </Col>
//           {warrantyType === "warrantyDays" && (
//             <Col span={8}>
//               <Form.Item
//                 name="warrantyDays"
//                 label="Warranty Date"
//               >
//                 <Input type="date" placeholder="Enter warranty Date" />
//               </Form.Item>
//             </Col>
//           )}
//           {warrantyType === "warrantyMonthly" && (
//             <Col span={8}>
//               <Form.Item
//                 name="warrantyMonthly"
//                 label="Monthly Warrenty"
//               >
//                 <Input type="number" placeholder="Enter warranty Months" />
//               </Form.Item>
//             </Col>
//           )}
//         </Row>
//         <Row gutter={16}>
//         <Col span={8}>
//             <Form.Item
//                 name="mmSize"
//                 label="mmSize"
//               >
//               <Input />
//             </Form.Item>
//           </Col>
//           <Col span={8}>
//             <Form.Item name="usedType" label="Used Type">
//               <Select showSearch placeholder="Select this product Used Type">
//                 <Select.Option value="USED">USED</Select.Option>
//                 <Select.Option value="REFURBISHED">REFURBISHED</Select.Option>
//               </Select>
//             </Form.Item>
//           </Col>   
//           <Col span={4}>
//             <Form.Item
//               name="gps"
//               label=" "
//               // initialValue={record.gps}
//               valuePropName="checked" // Maps checkbox value to true/false
//               // initialValue={false}
//             >
//               <Checkbox >GPS</Checkbox>
//             </Form.Item>
//           </Col>

//           <Col span={4}>
//             <Form.Item
//               name="cellular"
//               label=" "
//               valuePropName="checked" // Maps checkbox value to true/false
//               // initialValue={false}
//             >
//               <Checkbox >Cellular</Checkbox>
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
//           <Col span={24}>
//             <Form.Item
//               name="youtubeUrl"
//               label="YouTube URL"
//             >
//               <Input />
//             </Form.Item>
//           </Col>
//         </Row>
//         <Row gutter={16}>
          
//           <Col span={24}>
//             <Form.Item
//               name="images"
//               label="Images"
//               valuePropName="fileList"
//               getValueFromEvent={normFile}
//             >
//               <Upload {...props} listType="picture-card" accept="image/png, image/jpeg" multiple>
//                 <div>
//                   <PlusOutlined />
//                   <div style={{ marginTop: 8 }}>Upload</div>
//                 </div>
//               </Upload>
//             </Form.Item>
//           </Col>
//         </Row>
//       </Form>
//     </Modal>
//   );
// };

// export default ProductsUpdateForm;
