import React, { useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getBrands } from '../../store/actions/brand';

const ModelsForm = ({ visible, onCreate, onCancel, record, categoryOptions }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const brandList = useSelector((state) => state.brand.list);

  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (visible) {
      if (record) {
        form.setFieldsValue({
          name: record.name,
          category: record.category,
          brandId: record.brandId,
          order: record.order ? record.order : null,
        });
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  }, [visible, record, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onCreate(values);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Series" : "Add Series"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleSubmit}
      width={800}
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="model_form"
      >
        <Form.Item
          name="brandId"
          label="Brand"
          rules={[{ required: true, message: 'Please select a brand!' }]}
        >
          <Select placeholder="Select a brand">
            {brandList.map((brand) => (
              <Select.Option key={brand._id} value={brand._id}>
                {brand.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please select a product category!",
            },
          ]}
        >
          <Select 
            placeholder="Select a Category type"
            showSearch
            optionFilterProp="children"
          >
            {categoryOptions.map(category => (
              <Select.Option key={category.value} value={category.value}>
                {category.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the model name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="order"
          label="Order"
          rules={[{ message: "Please enter the series order!" }]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModelsForm;



// import React, { useEffect } from 'react';
// import { Form, Input, Modal, Select } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import { getBrands } from '../../store/actions/brand';

// const ModelsForm = ({ visible, onCreate, onCancel, record, categoryOptions }) => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   const brandList = useSelector((state) => state.brand.list);

//   useEffect(() => {
//     // Fetch brands on component mount
//     dispatch(getBrands());
//   }, [dispatch]);

//   useEffect(() => {
//     if (record) {
//       // When a record is selected for editing, set initial values
//       form.setFieldsValue({
//         _id: record._id,
//         name: record.name,
//         category: record.category || [],  // Ensure category is an array if empty
//         brandId: record.brandId,
//       });
//     } else {
//       // Reset the form fields when no record is selected (for new model)
//       form.resetFields();
//     }
//   }, [record, form]);
  

//   // useEffect(() => {
//   //   if (record) {const formData = {
//   //     ...record,
//   //     category: record.category || [] // Ensure category is an array if it's empty
//   //   };
//   //     form.setFieldsValue(record);
//   //   }
//   // }, [record, form]);

//   const handleChange = (value) => {
//     console.log(`selected ${value}`);
//   };

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Model" : "Add Model"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="model_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="brandId"
//           label="Brand"
//           rules={[{ required: true, message: 'Please select a brand!' }]}
//         >
//           <Select placeholder="Select a brand" onChange={handleChange}>
//             {brandList.map((brand) => (
//               <Select.Option key={brand._id} value={brand._id}>
//                 {brand.name}
//               </Select.Option>
//             ))}
//           </Select>
//           </Form.Item>
//           <Form.Item
//           name="category"
//           label="Category"
//           rules={[
//             {
//               required: true,
//               message: "Please select a product category!",
//             },
//           ]}
//         >
//           <Select 
//             placeholder="Select a Category type"
//             showSearch
//             optionFilterProp="children"
//           >
//             {categoryOptions.map(category => (
//               <Select.Option key={category.value} value={category.value}>
//                 {category.label}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//           {/* <Form.Item
//               name="category"
//               label="Category"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please enter the product category!",
//                 },
//               ]}
//             >
//               <Select placeholder="Select a Category type">
//                 <Select.Option value="Mobile">Mobile</Select.Option>
//                 <Select.Option value="Tablet">Tablet</Select.Option>
//                 <Select.Option value="Watch">Watch</Select.Option>
//                 <Select.Option value="Airpod">Airpod</Select.Option>
//                 <Select.Option value="Accessories">Accessories</Select.Option>
//               </Select>
//           </Form.Item> */}
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the model name!' }]}
//         >
//           <Input />
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default ModelsForm;


// import React, { useEffect } from 'react';
// import { Form, Input, Modal, Select } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import { getBrands } from '../../store/actions/brand';

// const ModelsForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   const brand = useSelector((state) => state.brand.list);

//   useEffect(() => {
//     // Fetch brands on component mount
//     dispatch(getBrands());
//   }, [dispatch]);

//   useEffect(() => {
//     form.setFieldsValue(record);
//   }, [record, form]);

//   const handleChange = (value) => {
//     console.log(`selected ${value}`);
//   };

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     return e?.fileList;
//   };

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Model" : "Add Model"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="model_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the model name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="brandId"
//           label="Brand"
//           rules={[{ required: true, message: 'Please select a brand!' }]}
//         >
//           <Select placeholder="Select a brand">
//             {brand.map((brand) => (
//               <Select.Option key={brand._id} value={brand._id}>
//                 {brand.name}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default ModelsForm;

// // const { Option } = Select;

// // const ProductsForm = ({ visible, onCreate, onCancel, record }) => {
// //   const [form] = Form.useForm();

// //   // Set initial values when the record changes
// //   React.useEffect(() => {
// //     form.setFieldsValue(record);
// //   }, [record, form]);

// //   return (
// //     <Modal
// //       visible={visible}
// //       title={record ? "Update Product" : "Add Product"}
// //       okText="Submit"
// //       cancelText="Cancel"
// //       onCancel={onCancel}
// //       onOk={() => {
// //         form
// //           .validateFields()
// //           .then(values => {
// //             onCreate(values);
// //             form.resetFields();
// //           })
// //           .catch(info => {
// //             console.log('Validate Failed:', info);
// //           });
// //       }}
// //     >
// //       <Form
// //         form={form}
// //         layout="vertical"
// //         name="product_form"
// //         initialValues={record}
// //       >
// //         <Form.Item
// //           name="name"
// //           label="Name"
// //           rules={[{ required: true, message: 'Please enter the product name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="brandName"
// //           label="Brand"
// //           rules={[{ required: true, message: 'Please enter the brand name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="modelName"
// //           label="Model"
// //           rules={[{ required: true, message: 'Please enter the model name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="seriesName"
// //           label="Series"
// //           rules={[{ required: true, message: 'Please enter the series name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="color"
// //           label="Color"
// //           rules={[{ required: true, message: 'Please enter the color!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="price"
// //           label="Price"
// //           rules={[{ required: true, message: 'Please enter the price!' }]}
// //         >
// //           <InputNumber min={0} style={{ width: '100%' }} />
// //         </Form.Item>
// //         <Form.Item
// //           name="storage"
// //           label="Storage"
// //           rules={[{ required: true, message: 'Please enter the storage!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="network"
// //           label="Network"
// //           rules={[{ required: true, message: 'Please enter the network!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="osNo"
// //           label="OS"
// //           rules={[{ required: true, message: 'Please enter the OS!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="inStock"
// //           label="In Stock"
// //           valuePropName="checked"
// //         >
// //           <Input type="checkbox" />
// //         </Form.Item>
// //       </Form>
// //     </Modal>
// //   );
// // };

// // export default ProductsForm;